<template>
  <b-tabs v-model="tabIndex">
    <b-tab title="Audit Findings">
      <AuditFindings :tabIndex="tabIndex" />
    </b-tab>
    <b-tab title="Configurations">
      <Configurations :tabIndex="tabIndex" />
    </b-tab>
    <b-tab title="Scans">
      <CloudScans :tabIndex="tabIndex" />
    </b-tab>
  </b-tabs>
</template>
<script>
import { BCard, BTab, BTabs } from "bootstrap-vue";
import Configurations from "./configuration.vue";
import CloudScans from "./cloudScans.vue";
import AuditFindings from "./auditFindings.vue";
export default {
  components: {
    BCard,
    BTab,
    BTabs,
    Configurations,
    CloudScans,
    AuditFindings,
  },
  data() {
    return {
      tabIndex: 0,
    };
  },
  created: function () {
    this.setTabIndexFromPathQuery();
  },

  methods: {
    setTabIndexFromPathQuery() {
      if (this.$route.query.tabIndex && this.$route.query.tabIndex != null) {
        this.tabIndex = Number(this.$route.query.tabIndex);
        this.$router.replace({ query: null });
      }
    },
  },
};
</script>