<template>
  <b-card-code>
    <div class="d-flex justify-content-center mb-1" v-if="loading">
      <b-spinner class="float-right" label="Floated Right" />
    </div>
    <br />
    <div v-if="!loading">
      <div v-if="items.length">
        <b-table
          id="atTable"
          :fields="fields"
          :items="items"
          :per-page="perPage"
          hover
          responsive
          style="min-height: 200px"
        >
          <template #cell(index)="data">
            {{ data.index + 1 + perPage * (currentPage - 1) }}
          </template>
          <template #cell(cloud_type)="data">
            <span class="text-uppercase">{{
              data.item.cloud_configuration.cloud_type
            }}</span>
          </template>

          <template #cell(scan_status)="data">
            <b-badge variant="primary" v-if="data.item.scan_status == 0"
              >Scheduled</b-badge
            >
            <b-badge
              variant="warning"
              v-else-if="
                data.item.scan_status == 1 || data.item.scan_status == 6
              "
              >In Progress</b-badge
            >
            <b-badge variant="success" v-else-if="data.item.scan_status == 2"
              >Completed</b-badge
            >
            <b-badge variant="primary" v-else-if="data.item.scan_status == 5"
              >Pre-scheduled</b-badge
            >
            <b-badge variant="danger" v-else>Failed</b-badge>
          </template>
          <template #cell(errors)="data">
            <div style="white-space: nowrap; overflow: hidden" v-if="data.item.errors">
              <div style="text-overflow: ellipsis">
                <span v-html="breakTextIntoLines(data.item.errors)"></span>
              </div>
            </div>
          </template>
          <template #cell(actions)="data">
            <b-dropdown
              size="sm"
              class="ml-1"
              variant="outline-primary"
              text="Actions"
            >
              <b-dropdown-item
                v-if="data.item.scan_status == 0 || data.item.scan_status == 1"
                @click="refresh(data.item.scan_id)"
                >Refresh</b-dropdown-item
              >
              <b-dropdown-divider />
              <b-dropdown-item
                @click="
                  gotoDelete(
                    data.item.scan_id,
                    data.item.cloud_configuration.cloud_type
                  )
                "
                >Delete</b-dropdown-item
              >
            </b-dropdown>
          </template>
        </b-table>
      </div>
      <div v-else>
        <div
          class="w-100 d-flex flex-column align-items-center justify-content-center py-2"
        >
          <feather-icon icon="DatabaseIcon" size="34" class="mb-50" />
          <h3 class="font-weight-bolder">No Data Found</h3>
        </div>
      </div>
      <b-pagination-nav
        v-model="currentPage"
        :numberOfPages="total"
        :total-rows="rows"
        :link-gen="linkGen"
        :per-page="perPage"
        :align="pagination_pos"
        aria-controls="atTable"
        use-router
      />
    </div>
    <b-modal
      id="modal-danger"
      hide-footer
      modal-class="modal-danger"
      centered
      title="Delete.. !"
      v-model="openDeleteModal"
      size="lg"
    >
      <b-card-text>
        Do you really want to delete {{ cloud_type }} ?
      </b-card-text>
      <div class="d-flex justify-content-end">
        <b-button @click="deleteGroup()" variant="danger" size="sm">
          Delete
        </b-button>
      </div>
    </b-modal>
  </b-card-code>
</template>

<script>
import vSelect from "vue-select";
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import { BTable } from "bootstrap-vue";
import {
  BCard,
  BPagination,
  BPaginationNav,
  BDropdown,
  BDropdownItem,
  BDropdownDivider,
  BForm,
  BFormInput,
  BFormSelect,
  BSpinner,
  BButton,
  BBadge,
  BSidebar,
  BFormGroup,
  VBToggle,
  BTabs,
  BTab,
} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
// import AssetsDiscover from "../AssetDiscovery/components/assets_discovered.vue";
import moment from "moment";
import { ValidationProvider, ValidationObserver } from "vee-validate";

export default {
  components: {
    BCard,
    BCardCode,
    BTable,
    BPagination,
    BPaginationNav,
    BDropdown,
    BDropdownItem,
    BDropdownDivider,
    BForm,
    VBToggle,
    BFormInput,
    BFormSelect,
    BSpinner,
    BButton,
    BBadge,
    BSidebar,
    BFormGroup,
    ValidationProvider,
    ValidationObserver,
    BTabs,
    BTab,
    vSelect,
  },
  directives: {
    "b-toggle": VBToggle,
  },
  data() {
    return {
      userId: localStorage.getItem("userid"),
      scan_id: 0,
      selected_group: 0,
      pagination_pos: "center",
      items: [],
      currentPage: 1,
      perPage: 10,
      rows: 0,
      total: 1,
      fields: [
        { key: "index", label: "#" },
        { key: "cloud_configuration.nice_name", label: "Name" },
        { key: "cloud_type", label: "Type" },
        { key: "scan_status", label: "Status" },
        { key: "errors", label: "Errors" },
        // { key: "created_at", label: "Created At" },
        {
          key: "cloud_configuration.created_by.first_name",
          label: "Created By",
        },
        { key: "actions", label: "Actions" },
      ],
      is_staff: this.$store.state.app.user.is_staff,
      organization: this.$store.state.app.org_id,
      scheduleorganization: null,
      domain_organization: null,
      organizations: [],
      name_filter: "",

      loading: false,

      openDeleteModal: false,
      scan_id: "",
      cloud_type: "",
    };
  },
  props: {
    tabIndex: {
      type: Number,
      required: true,
    },
  },

  watch: {
    // agent_types: {
    //   handler(newAgentTypes, oldAgentTypes) {
    //     if (newAgentTypes.length > 0) {
    //       this.load();
    //     }
    //   },
    //   immediate: true,
    // },
    tabIndex: function (newVal, oldVal) {
      if (newVal === 2) {
        this.load();
      }
    },
    currentPage: function (newVal, oldVal) {
      this.searchFn(false);
    },
  },
  filters: {
    formatdate: function (value) {
      if (value) {
        return moment(String(value)).format("DD-MMM-YY hh:mm A");
      }
    },
  },
  created() {
    if (this.tabIndex === 2) {
      this.load();
    }
  },
  methods: {
    linkGen: function (pageNum) {
      return this.$router.currentRoute.path + "?page=" + pageNum;
    },
    load: function () {
      this.loading = true;

      if (this.$route.query.page) this.currentPage = this.$route.query.page;
      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url: process.env.VUE_APP_BASEURL + "scan/cloud-review-scan?agent_code=prowler",
      };
      this.$http(options).then((res) => {
        this.items = res.data.results;
        this.rows = res.data.results.length;
        this.total = Math.ceil(res.data.count / this.perPage);
        this.loading = false;
      });
    },
    breakTextIntoLines(text) {
      const maxCharacters = 45;
      const words = text.split(/\s+/);
      const lines = [];

      let currentLine = "";

      for (const word of words) {
        if ((currentLine + word).length <= maxCharacters) {
          currentLine += (currentLine ? " " : "") + word;
        } else {
          lines.push(currentLine);
          currentLine = word;
        }
      }
      if (currentLine) {
        lines.push(currentLine);
      }
      return lines.join("<br>");
    },
    gotoDelete(scan_id, cloud_type) {
      this.openDeleteModal = true;
      this.scan_id = scan_id;
      this.cloud_type = cloud_type;
    },
    deleteGroup: function () {
      const options = {
        method: "DELETE",
        headers: { "content-type": "application/json" },
        url:
          process.env.VUE_APP_BASEURL +
          "scan/cloud-review-scan/" +
          this.scan_id +
          "/",
      };
      var self = this;
      this.$http(options).then((res) => {
        if (res.data == "") {
          self.$toast({
            component: ToastificationContent,
            props: {
              title: "Successfully deleted",
              icon: "TrashIcon",
              variant: "success",
            },
          });
          this.openDeleteModal = false;
          this.searchFn();
        }
      });
    },

    searchFn: function (reset = true) {
      this.is_search_on = false;
      this.loading = true;
      this.filter = [];
      if (reset) this.currentPage = 1;
      let url =
        process.env.VUE_APP_BASEURL +
        "scan/cloud-review-scan?query=true&agent_code=prowler" +
        "&page=" +
        this.currentPage;
      if (this.name_filter != null && this.name_filter != "") {
        this.filter.push({ name: this.name_filter });
        url = url + "&name=" + this.name_filter;
      }
      if (this.filter.length) this.is_search_on = true;
      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url: url,
      };
      this.$http(options).then((res) => {
        this.items = res.data.results;
        this.rows = res.data.results.length;
        this.total = Math.ceil(res.data.count / this.perPage);
        this.loading = false;
      });
    },

    refresh: function (id) {
      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url:
          process.env.VUE_APP_BASEURL +
          "scan/cloud-review-scan/" +
          id +
          "/scan-refresh/",
      };
      var self = this;
      this.$http(options).then((res) => {
        this.searchFn();
      });
    },
  },
};
</script>
<style scoped>
::v-deep .dropdown-menu {
  max-height: 200px;
  overflow-y: auto;
}
.bg-dark-blue {
  /* background-color: #171153 !important; */
  background-color: #1a457b1f !important;
  /* background-color: #1e176c !important; */
}

[dir] .dark-layout .bg-dark-blue {
  background-color: #04002f !important;
  color: #fff;
}
</style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
