<template>
  <b-card-code>
    <b-button
      type="button"
      variant="primary"
      class="my-1"
      size="sm"
      @click="gotoCreateCloud()"
    >
      + Add New
    </b-button>
    <b-card-code class="bg-light-secondary mb-1" title="Search">
      <b-form inline style="display: inline-block; width: 100%">
        <div class="row" style="width: 100%; padding: 5px">
          <b-form-input
            class="ml-1"
            id="name"
            v-model="cloud_nice_name_filter"
            style="width: 40%"
            name="name"
            placeholder="Search with Name"
          />
          <v-select
            style="width: 30%"
            class="ml-1"
            v-model="cloud_type_filter"
            label="text"
            placeholder="--Select cloud type--"
            :options="cloud_type_options"
            autocomplete
            :reduce="(cloud) => cloud.value"
          />
          <b-button
            style="width: 12%"
            class="ml-1"
            variant="secondary"
            @click="refresh"
          >
            Refresh
          </b-button>
          <b-button
            style="width: 12%"
            class="ml-1"
            variant="primary"
            @click="load"
          >
            <span v-if="isloading"
              ><b-spinner type="border" small></b-spinner> Please wait</span
            >
            <span v-else>
              <feather-icon icon="SearchIcon" class="mr-50" />Search</span
            >
          </b-button>
        </div>
      </b-form>
    </b-card-code>
    <div class="d-flex justify-content-center mb-1" v-if="loading">
      <b-spinner class="float-right" label="Floated Right" />
    </div>
    <div v-if="!loading">
      <b-table
        v-if="items.length"
        responsive
        id="configTable"
        :fields="fields"
        :items="items"
        :per-page="perPage"
        hover
      >
        <template #cell(index)="data">
          {{ data.index + 1 + perPage * (currentPage - 1) }}
        </template>
        <template #cell(created_at)="data">
          {{ data.item.created_at | formatdate }}
        </template>
        <template #cell(cloud_type)="data">
          <span class="text-uppercase">{{ data.item.cloud_type }}</span>
        </template>
        <template #cell(risk_level)="data">
          <b-badge
            style="background-color: #991d28"
            v-if="data.item.risk_level == 6"
            >Critical</b-badge
          >
          <b-badge variant="danger" v-else-if="data.item.risk_level == 5"
            >High</b-badge
          >
          <b-badge variant="warning" v-else-if="data.item.risk_level == 4"
            >Medium</b-badge
          >
          <b-badge variant="primary" v-else-if="data.item.risk_level == 3"
            >Low</b-badge
          >
          <b-badge variant="success" v-else-if="data.item.risk_level == 2"
            >Safe</b-badge
          >
          <b-badge variant="secondary" v-else-if="data.item.risk_level == 1"
            >Muted</b-badge
          >
          <b-badge variant="secondary" v-else-if="data.item.risk_level == 0"
            >Unknown</b-badge
          >
        </template>

        <template #cell(actions)="data">
          <b-dropdown
            size="sm"
            class="ml-1"
            variant="outline-primary"
            text="Actions"
          >
            <b-dropdown-item
              @click="
                gotoEdit(
                  data.item.id,
                  data.item.nice_name,
                  data.item.cloud_type
                )
              "
              >Edit</b-dropdown-item
            >
            <b-dropdown-divider />
            <b-dropdown-item
              @click="gotoDelete(data.item.id, data.item.nice_name)"
              >Delete</b-dropdown-item
            >
          </b-dropdown>
          <b-button
            variant="primary"
            size="sm"
            class="ml-1"
            @click="review(data.item.id)"
            :disabled="loadingStates[data.item.id]"
            v-if="
              data.item.cloud_type === 'aws' ||
              data.item.cloud_type === 'azure' ||
              data.item.cloud_type === 'gcp'
            "
          >
            <span v-if="loadingStates[data.item.id]"
              ><b-spinner type="border" small></b-spinner> please wait</span
            >
            <span v-else>Review</span>
          </b-button>
        </template>
      </b-table>
      <div v-else>
        <div
          class="w-100 d-flex flex-column align-items-center justify-content-center py-2"
        >
          <feather-icon icon="GlobeIcon" size="34" class="mb-50" />
          <h3 class="font-weight-bolder">No Cloud Configuration</h3>
          <p>
            To add a new Cloud Configuration,
            <span
              class="font-weight-bold text-success cursor-pointer"
              @click="gotoCreateCloud()"
              >Click here</span
            >
          </p>
        </div>
      </div>
    </div>
    <b-pagination-nav
      v-model="currentPage"
      :numberOfPages="total"
      :total-rows="rows"
      :link-gen="linkGen"
      :per-page="perPage"
      :align="pagination_pos"
      aria-controls="configTable"
      use-router
    />

    <b-sidebar
      id="sidebar-edit-Cloud"
      sidebar-class="sidebar-lg"
      bg-variant="white"
      shadow
      backdrop
      no-header
      right
      width="600px"
    >
      <!-- Header -->
      <EditCloud
        :closeSidebar="closeSidebar"
        :cloudTtype="cloudTtype"
        :cloudtitle="cloudtitle"
        :cloudId="cloudId"
      />
    </b-sidebar>

    <b-modal
      id="modal-danger"
      hide-footer
      modal-class="modal-danger"
      centered
      title="Delete.. !"
      v-model="openDeleteModal"
      size="lg"
    >
      <b-card-text>
        Do you really want to delete {{ cloudtitle }} ?
      </b-card-text>
      <div class="d-flex justify-content-end">
        <b-button @click="deleteCloud()" variant="danger" size="sm">
          Delete
        </b-button>
      </div>
    </b-modal>
  </b-card-code>
</template>

<script>
import vSelect from "vue-select";
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import { BTable } from "bootstrap-vue";
import moment from "moment";
import {
  BPagination,
  BPaginationNav,
  BDropdown,
  BDropdownItem,
  BDropdownDivider,
  BForm,
  BFormInput,
  BFormSelect,
  BSpinner,
  BButton,
  BAvatar,
  BSidebar,
  BBadge,
} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import EditCloud from "./edit.vue";

export default {
  components: {
    BCardCode,
    BTable,
    BPagination,
    BPaginationNav,
    BDropdown,
    BDropdownItem,
    BDropdownDivider,
    BForm,
    BFormInput,
    BFormSelect,
    BSpinner,
    BButton,
    vSelect,
    BAvatar,
    BSidebar,
    EditCloud,
    BBadge,
  },
  data() {
    return {
      pagination_pos: "center",
      items: [],
      currentPage: 1,
      perPage: 10,
      rows: 0,
      fields: [
        { key: "index", label: "#" },
        { key: "nice_name", label: "Name" },
        { key: "cloud_type", label: "Type" },
        { key: "risk_level", label: "Risk Level" },
        // { key: "created_at", label: "Created At" },
        { key: "created_by.first_name", label: "Created By" },
        { key: "actions", label: "Actions" },
      ],
      is_staff: this.$store.state.app.user.is_staff,
      organization: this.$store.state.app.org_id,
      isloading: false,
      loading: false,
      openDeleteModal: false,
      cloudId: null,
      cloud_title: "",
      cloud_nice_name_filter: "",
      cloud_type: "",
      cloudTtype: "",
      cloud_type_filter: "",
      cloud_type_options: [
        // { value:null , text :"Select Cloud Type"},
        { value: "aws", text: "AWS" },
        { value: "azure", text: "Azure" },
        { value: "gcp", text: "Google Cloud" },
        { value: "kubernetes", text: "Kubernetes" },
      ],
      loadingPreview: false,
      isDisableReview: false,
      loadingStates: {},
    };
  },

  props: {
    tabIndex: {
      type: Number,
      required: true,
    },
  },
  watch: {
    tabIndex: function (newVal, oldVal) {
      if (newVal === 1) {
        this.load();
      }
    },
    currentPage: function (newVal, oldVal) {
      this.load(false);
    },
  },
  created: function () {
    if (this.tabIndex === 1) {
      this.load();
    }
  },
  filters: {
    formatdate: function (value) {
      if (value) {
        return moment(String(value)).format("DD-MMM-YY hh:mm A");
      }
    },
  },
  methods: {
    linkGen: function (pageNum) {
      return this.$router.currentRoute.path + "?page=" + pageNum;
    },
    load: function (reset = true) {
      this.loading = true;
      if (reset) this.currentPage = 1;
      let url =
        process.env.VUE_APP_BASEURL +
        "organization/cloud-config?query=true" +
        "&page=" +
        this.currentPage;
      if (this.cloud_nice_name_filter != "") {
        url = url + "?nice_name=" + this.cloud_nice_name_filter;
      }
      if (this.cloud_type_filter != "") {
        url = url + "?cloud_type=" + this.cloud_type_filter;
      }

      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url: url,
      };
      this.$http(options).then((res) => {
        this.items = res.data.results;
        this.rows = res.data.length;
        this.total = Math.ceil(res.data.count / this.perPage);
        this.loading = false;
        this.loadAgentTypes();
      });
    },
    gotoCreateCloud() {
      this.$router.push({ path: "/cloud-configuration/add" });
    },
    gotoEdit: function (id, nice_name, type) {
      this.cloudId = id;
      this.cloudtitle = nice_name;
      this.cloudTtype = type;
      this.$root.$emit("bv::toggle::collapse", "sidebar-edit-Cloud");
    },
    closeSidebar: function () {
      this.$root.$emit("bv::toggle::collapse", "sidebar-edit-Cloud");
      this.load();
    },
    gotoDelete(id, nice_name) {
      this.openDeleteModal = true;
      this.cloudId = id;
      this.cloudtitle = nice_name;
    },
    deleteCloud() {
      const options = {
        method: "DELETE",
        headers: { "content-type": "application/json" },
        url:
          process.env.VUE_APP_BASEURL +
          "organization/cloud-config/" +
          this.cloudId +
          "/",
      };
      var self = this;
      this.$http(options).then((res) => {
        if (res.data == "") {
          self.$toast({
            component: ToastificationContent,
            props: {
              title: "Successfully deleted",
              icon: "TrashIcon",
              variant: "success",
            },
          });
          this.openDeleteModal = false;
          this.load();
        }
      });
    },
    refresh() {
      this.cloud_nice_name_filter = "";
      this.cloud_type_filter = "";
      this.load();
    },
    loadAgentTypes: function () {
      const a_options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url: process.env.VUE_APP_BASEURL + "agent/type",
      };
      var self = this;
      this.$http(a_options).then((res) => {
        // console.log(res.data)
        this.agent_types = res.data;
        // console.log(this.conf_items)
        this.agent_types = this.agent_types.filter(
          (item) => item.agent_type_code !== "hibp"
        );
      });
    },

    review(id) {
      // this.isDisableReview = true;
      // this.loadingPreview = true;

      this.$set(this.loadingStates, id, true);
      const selectedAgentType = this.agent_types.find(
        (item) => item.agent_type_code === "prowler"
      );
      this.agent_id = selectedAgentType
        ? selectedAgentType.agent_type_id
        : null;
      let data = {
        agent_type: this.agent_id,
        scan_created_by: this.$store.state.app.user.id,
        cloud_config: id,
      };
      const options = {
        method: "POST",
        headers: { "content-type": "application/json" },
        data: data,
        url: process.env.VUE_APP_BASEURL + "scan/cloud-review-scan/",
      };
      this.$http(options)
        .then((res) => {
          if (res.data.errors) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: res.data.errors,
                autoHideDelay: 5000,
                icon: "EditIcon",
                variant: "danger",
              },
            });
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Scan Successfully created",
                autoHideDelay: 5000,
                icon: "EditIcon",
                variant: "success",
              },
            });
          }
          // this.loadingPreview = false;
          // this.isDisableReview = false;
        })
        .catch((err) => {
          console.log(err);
          // this.loadingPreview = false;
          // this.isDisableReview = false;
        })
        .finally(() => {
          this.$set(this.loadingStates, id, false); // Stop loading for this item
        });
    },
  },
};
</script>
<style scoped>
::v-deep .dropdown-menu {
  max-height: 200px;
  overflow-y: auto;
}
</style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
