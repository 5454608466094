var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',[_c('div',{staticClass:"d-flex justify-content-end"},[_c('feather-icon',{staticClass:"ml-1 cursor-pointer",attrs:{"icon":"XIcon","size":"16"},on:{"click":function($event){return _vm.closeSidebar()}}})],1),_c('validation-observer',{ref:"CloudConfigEditForm"},[_c('b-form',{staticClass:"auth-login-form mt-2",on:{"submit":function($event){$event.preventDefault();return _vm.editCloudConfig($event)}}},[_c('b-form-group',{attrs:{"label":"cloud type","label-for":"cloud type"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',[_vm._v("Cloud Type")]),_c('span',{staticClass:"text-danger font-weight-bold h5",staticStyle:{"margin-left":"3px"}},[_vm._v("*")])]},proxy:true}])},[_c('validation-provider',{attrs:{"name":"cloud type","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"label":"text","placeholder":"--Select cloud type--","options":_vm.cloud_type_options,"autocomplete":"","reduce":function (cloud) { return cloud.value; }},model:{value:(_vm.cloudTtype),callback:function ($$v) {_vm.cloudTtype=$$v},expression:"cloudTtype"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"cloud name","label-for":"cloud name"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',[_vm._v("Cloud Name")]),_c('span',{staticClass:"text-danger font-weight-bold h5",staticStyle:{"margin-left":"3px"}},[_vm._v("*")])]},proxy:true}])},[_c('validation-provider',{attrs:{"name":"Cloud Name","rules":{
            required: true,
            regex: /^[a-zA-Z0-9\s\(\)\ [\]\ {\} \|\/ ]+$/,
          }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"cloud_name","state":errors.length > 0 ? false : null,"name":"cloud_name","placeholder":"Please enter cloud name"},model:{value:(_vm.cloudtitle),callback:function ($$v) {_vm.cloudtitle=$$v},expression:"cloudtitle"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-button',{staticClass:"my-1",attrs:{"type":"submit","variant":"primary","disabled":_vm.cloudtitle === '' || _vm.disable,"size":"sm"}},[(_vm.isloading)?_c('span',[_c('b-spinner',{attrs:{"type":"border","small":""}}),_vm._v(" Please wait")],1):_c('span',[_vm._v("Update")])])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }