<template>
  <div>
    <div
      class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
    >
      <div class="d-flex justify-content-start">
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="closeSidebar()"
        />
      </div>
      <div>
        <b-badge class="mr-1" v-if="details.is_verified" variant="success"
          >Verified</b-badge
        >
        <b-badge class="mr-1" v-else variant="danger">Unverified</b-badge>

        <b-badge v-if="details.status == 0" variant="danger">Open</b-badge>
        <b-badge v-if="details.status == 1" variant="success">Closed</b-badge>
        <b-badge v-if="details.status == 2" variant="warning"
          >False Positive</b-badge
        >
        <b-dropdown
          size="sm"
          class="ml-1"
          variant="outline-primary"
          text="Actions"
        >
          <!-- <b-dropdown-item @click="editIssue(details)">Edit</b-dropdown-item> -->
          <b-dropdown-item
            v-if="details.status == 0"
            @click="doBulkAction(details, 1)"
            >Mark as closed</b-dropdown-item
          >
          <b-dropdown-item
            v-if="details.status == 0"
            @click="doBulkAction(details, 3)"
            >Accept the risk</b-dropdown-item
          >
          <b-dropdown-item
            v-if="details.status != 0"
            @click="doBulkAction(details, 0)"
            >Re-open</b-dropdown-item
          >
          <b-dropdown-item
            v-if="details.status == 0"
            @click="doBulkAction(details, 2)"
            >Mark as False Positive</b-dropdown-item
          >
          <!-- <div>
            <b-dropdown-item
              v-if="details.asset"
              @click="OpenTaskModal(details.asset.asset_id)"
              >Mitigate</b-dropdown-item
            >
            <b-dropdown-item v-else @click="OpenTaskModal(details.asset_id)"
              >Mitigate</b-dropdown-item
            >
          </div> -->

          <b-dropdown-divider />
          <b-dropdown-item
            v-if="details.is_verified && this.$store.state.app.user.is_staff"
            @click="doBulkAction(details, 11)"
            >Mark as un-verified</b-dropdown-item
          >
          <b-dropdown-item
            v-else-if="
              !details.is_verified && this.$store.state.app.user.is_staff
            "
            @click="doBulkAction(details, 10)"
            >Mark as verified</b-dropdown-item
          >
          <b-dropdown-item @click="doBulkAction(details, 9)"
            >Delete</b-dropdown-item
          >
        </b-dropdown>
      </div>
    </div>
    <b-card no-header>
      <div class="mb-1">
        <h4>{{ details.title }}</h4>
      </div>
      <b-card align="center" class="bg-dark-blue h-100">
        <div class="row">
          <div class="col">
            <b-list-group class="list-group-circle text-left bg-transparent">
              <b-list-group-item class="bg-transparent">
                <b>Severity: </b>
                <span class="">
                  <span class="">
                    <b-badge
                      style="background-color: #991d28"
                      v-if="details.severity == 'Critical'"
                      >Critical</b-badge
                    >
                    <b-badge
                      variant="danger"
                      v-else-if="details.severity == 'High'"
                      >High</b-badge
                    >
                    <b-badge
                      variant="warning"
                      v-else-if="details.severity == 'Medium'"
                      >Medium</b-badge
                    >
                    <b-badge
                      variant="primary"
                      v-else-if="details.severity == 'Low'"
                      >Low</b-badge
                    >
                    <b-badge
                      variant="success"
                      v-else-if="details.severity == 'Info'"
                      >Info</b-badge
                    >
                    <b-badge variant="secondary" v-else>Unknown</b-badge>
                  </span>
                </span>
              </b-list-group-item>
              <b-list-group-item class="bg-transparent">
                <b>Severity Score: </b>

                <span>{{ details.severity_score }}</span>
              </b-list-group-item>
              <b-list-group-item class="bg-transparent">
                <b>Status Code: </b>
                <span class="">
                  <b-badge
                    variant="success "
                    v-if="details.status_code == 'PASS'"
                    >PASS</b-badge
                  >

                  <b-badge
                    variant="danger"
                    v-else-if="details.status_code == 'FAIL'"
                    >FAIL</b-badge
                  >
                  <b-badge variant="warning" v-else>{{
                    details.status_code
                  }}</b-badge>
                </span>
              </b-list-group-item>
              <b-list-group-item class="bg-transparent">
                <b>Cloud Configuration:</b>
                <span class=""> {{ details.cloud_configuration }}</span>
              </b-list-group-item>
              <b-list-group-item class="bg-transparent">
                <b>Status Detail:</b>
                <span class=""> {{ details.status_detail }}</span>
              </b-list-group-item>

              <b-list-group-item class="bg-transparent">
                <b>Service: </b>
                <span class="text-capitalize">{{ details.service }} </span>
              </b-list-group-item>
              <b-list-group-item class="bg-transparent">
                <b>Provider: </b>
                <span class="text-uppercase">{{ details.provider }}</span>
              </b-list-group-item>
              <b-list-group-item class="bg-transparent" v-if="details.region">
                <b>Region: </b>
                <span class="text-uppercase">{{ details.region }}</span>
              </b-list-group-item>
              <b-list-group-item class="bg-transparent">
                <b class="mr-1">Resource ARN:</b>
                <span>{{ details.resource_arn }}</span>
              </b-list-group-item>
              <b-list-group-item class="bg-transparent">
                <b class="mr-1">Resource ID:</b>
                <span>{{ details.resource_id }}</span>
              </b-list-group-item>
              <b-list-group-item class="bg-transparent">
                <b class="mr-1">Account ID:</b>
                <span>{{ details.account_id }}</span>
              </b-list-group-item>
              <b-list-group-item class="bg-transparent">
                <b class="mr-1">Created At:</b>
                <span>{{ details.created_at | formatdate }}</span>
              </b-list-group-item>
              <b-list-group-item class="bg-transparent">
                <b class="mr-1">Updated At:</b>
                <span>{{ details.updated_at | formatdate }}</span>
              </b-list-group-item>
            </b-list-group>
          </div>
        </div>
      </b-card>
      <div>
        <h5 class="text-primary">Description</h5>
        <p v-html="details.description"></p>
      </div>
      <div>
        <h5 class="text-primary">Remediation</h5>
        <p v-html="details.remediation"></p>
      </div>
      <div>
        <h5 class="text-primary">Compliance</h5>
        <div
          class="mx-1"
          v-for="(values, key, index) in parsedCompliance"
          :key="key"
        >
          <h6>{{ index + 1 }}. {{ key }}</h6>
          <ul>
            <li v-for="value in values" :key="value">{{ value }}</li>
          </ul>
        </div>
      </div>
      <div class="mt-1">
        <h5 class="text-primary">References</h5>
        <!-- <p v-html="details.references"></p> -->
        <b-form-textarea
          v-model="details.references"
          class="border-primary"
          rows="6"
          max-rows="10"
          disabled
        >
        </b-form-textarea>
      </div>
      <!-- {{ details }} -->
    </b-card>
  </div>
</template>

<script>
import moment from "moment";
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import {
  BCard,
  BTabs,
  BTab,
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BForm,
  BButton,
  BFormTextarea,
  BInputGroupAppend,
  BInputGroup,
  BFormSelect,
  BFormCheckbox,
  BFormFile,
  BSpinner,
  BListGroup,
  BListGroupItem,
  BBadge,
  BDropdown,
  BDropdownDivider,
  BDropdownItem,
} from "bootstrap-vue";

export default {
  components: {
    BCard,
    BCardCode,
    BForm,
    BFormInput,
    BFormTextarea,
    BFormGroup,
    BRow,
    BButton,
    BCol,
    BInputGroupAppend,
    BInputGroup,
    BFormSelect,
    BFormCheckbox,
    BTabs,
    BTab,
    BFormFile,
    BSpinner,
    BListGroup,
    BListGroupItem,
    BBadge,
    BDropdown,
    BDropdownDivider,
    BDropdownItem,
  },

  data() {
    return {};
  },
  props: {
    details: {
      type: Object,
      required: true,
    },
    closeSidebar: {
      type: Function,
      required: true,
    },
  },
  filters: {
    formatdate: function (value) {
      if (value) {
        return moment(String(value)).format("DD-MMM-YY hh:mm A");
      }
    },
  },
  computed: {
    parsedCompliance() {
      try {
        return JSON.parse(this.details.compliance);
      } catch (e) {
        return {}; // Return an empty object if parsing fails
      }
    },
  },
  created: function () {
    // this.load();
  },

  methods: {
    doBulkAction: function (details, status) {
      var self = this;
      this.loading = true;
      let data = {
        status: status,
        vulnerabilties: [this.details.id],
      };

      this.$http({
        method: "POST",
        data: data,
        headers: { "content-type": "application/json" },
        url:
          process.env.VUE_APP_BASEURL + "organization/cloud-vulns/vuln-bulk-action/",
      }).then((res) => {
        if (res.data.status == "success") {
          self.$toast({
            component: ToastificationContent,
            props: {
              title: res.data.message,
              icon: "EditIcon",
              variant: "success",
            },
          });
          this.selectedVulns = [];
          self.closeSidebar();
        } else {
          self.$toast({
            component: ToastificationContent,
            props: {
              title: res.data.message,
              icon: "EditIcon",
              variant: "danger",
            },
          });
        }
        this.loading = false;
      });
    },
  },
};
</script>
<style lang="css">
.b-sidebar.sidebar-lg {
  width: 60rem;
}

.bg-dark-blue {
  /* background-color: #171153 !important; */
  background-color: #1a457b1f !important;
  /* background-color: #1e176c !important; */
}

[dir] .dark-layout .bg-dark-blue {
  background-color: #131721 !important;
  color: #fff;
}

[dir].dark-layout .list-group-item {
  padding: 0.75rem 1.25rem;
  background-color: #283046 !important;
  border: 1px solid rgb(68 101 129) !important;
}
</style>
<style scoped>
.custom-list {
  min-height: 150px;
  max-height: 100px;
  overflow-y: auto;
}
.custom-list li:hover {
  color: #7367f0; /* Change the text color to blue on hover */
}
[dir] .list-group-item {
  background-color: #fff0 !important;
}
</style>