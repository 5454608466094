<template>
  <b-card-code>
    <b-card-code class="bg-light-secondary mb-1" title="Search">
      <b-form inline style="display: inline-block; width: 100%">
        <div class="row" style="width: 100%; padding: 5px">
          <b-form-input
            class="ml-1"
            id="name"
            v-model="title_filter"
            style="width: 49%"
            name="name"
            placeholder="Search with Title"
          />
          <b-form-input
            class="ml-1"
            id="compliance"
            v-model="compliance_filter"
            style="width: 48%"
            name="name"
            placeholder="Search with Compliance"
          />

          <div class="w-100 d-flex justify-content-between mt-1">
            <v-select
              v-model="configuration_filter"
              label="text"
              class="ml-1"
              @search="fetchConfiguration"
              placeholder="--Search with Configuration--"
              :options="configuration_filter_options"
              autocomplete
              style="width: 40%"
              :reduce="(configuration_filter) => configuration_filter.value"
            />
            <v-select
              style="width: 19%"
              class="ml-1"
              v-model="status_code_filter"
              label="text"
              placeholder="--Select Status Code--"
              :options="status_code_options"
              autocomplete
              :reduce="(code) => code.value"
            />
            <v-select
              v-model="severity_filter"
              label="text"
              class="ml-1"
              placeholder="--Select Severity--"
              :options="severity_filter_options"
              autocomplete
              style="width: 19%"
              :reduce="(severity_filter) => severity_filter.value"
            />
            <b-button
              style="width: 12%"
              class="ml-1"
              variant="secondary"
              @click="refresh"
            >
              Refresh
            </b-button>
            <b-button
              style="width: 12%"
              class="ml-1"
              variant="primary"
              @click="load"
            >
              <span v-if="isloading"
                ><b-spinner type="border" small></b-spinner> Please wait</span
              >
              <span v-else>
                <feather-icon icon="SearchIcon" class="mr-50" />Search</span
              >
            </b-button>
          </div>
        </div>
      </b-form>
    </b-card-code>
    <div class="d-flex justify-content-between">
      <div class="d-flex">
        <b-form-checkbox
          class="ml-1"
          name="selected-items"
          v-model="isCheckAll"
          label="Select All"
          @change="checkAll()"
        >
          Select all in this page
        </b-form-checkbox>
        <b-form-checkbox
          class="ml-1"
          name="selected-items"
          v-model="isCheckAllFilterResults"
          label="Select All"
          v-if="is_search_on"
        >
          Select all issues that matches this search
        </b-form-checkbox>
      </div>
      <div class="clearfix ml-auto">
        <b-dropdown
          variant="outline-secondary"
          text="More Columns"
          checkbox-menu
          allow-focus
          class="mr-1"
        >
          <b-dropdown-form>
            <b-form-checkbox
              style="margin-top: 2px; margin-bottom: 2px"
              :disabled="visibleFields.length == 1 && field.visible"
              v-for="field in fields.slice(2)"
              :key="field.key"
              v-model="field.visible"
              inline
            >
              {{ field.label }}
            </b-form-checkbox>
          </b-dropdown-form>
        </b-dropdown>
        <b-form-select
          v-model="selected_action"
          :options="filteredOptions"
          @change="doBulkAction"
          style="width: 50%"
        >
        </b-form-select>
      </div>
    </div>
    <div v-if="!loading" class="clearfix">
      {{ audit_count }} Total Audit Findings
    </div>
    <div class="d-flex justify-content-center mb-1" v-if="loading">
      <b-spinner class="float-right" label="Floated Right" />
    </div>
    <div v-if="!loading">
      <b-table
        v-if="items.length"
        responsive
        id="auditTable"
        :fields="visibleFields"
        :items="items"
        :per-page="perPage"
        hover
      >
        <template v-slot:head(status_code)>
          <div
            class="d-flex justify-content-between"
            style="white-space: nowrap; overflow: hidden"
          >
            <span style="text-overflow: ellipsis" class="mr-1"
              >Status Code</span
            >
            <div class="d-flex flex-column">
              <feather-icon
                icon="ChevronUpIcon"
                class="mr-50"
                @click="setSort(0)"
                style="height: 10px"
              />
              <feather-icon
                icon="ChevronDownIcon"
                class="mr-50"
                @click="setSort(1)"
                style="height: 10px"
              />
            </div>
          </div>
        </template>
        <template v-slot:head(cloud_configuration)>
          <div style="white-space: nowrap; overflow: hidden">
            <span style="text-overflow: ellipsis" class="mr-1"
              >Cloud Configuration</span
            >
          </div>
        </template>
        <template #cell(index)="data">
          {{ data.index + 1 + perPage * (currentPage - 1) }}
        </template>
        <template #cell(selected_vulns)="data">
          <b-form-checkbox
            name="selected-items"
            v-model="selectedVulns"
            :value="data.item.id"
            @change="updateCheckAll()"
          >
          </b-form-checkbox>
        </template>
        <template #cell(title)="data">
          <div style="white-space: nowrap; overflow: hidden">
            <div style="text-overflow: ellipsis">
              <a @click="goMore(data.item)">
                <span v-html="breakTextIntoLines(data.item.title)"></span
              ></a>
            </div>
          </div>
        </template>
        <template #cell(created_at)="data">
          {{ data.item.created_at | formatdate }}
        </template>
        <template #cell(is_verified)="data">
          <b-avatar v-if="data.item.is_verified" variant="success">
            <feather-icon icon="ThumbsUpIcon" />
          </b-avatar>
          <b-avatar v-else variant="danger">
            <feather-icon icon="ThumbsDownIcon" />
          </b-avatar>
        </template>
        <template v-slot:cell(is_exploited)="data">
          <b-badge variant="success" v-if="data.item.is_exploited == true"
            >Yes</b-badge
          >
          <b-badge variant="danger" v-else>No</b-badge>
        </template>
        <template v-slot:cell(severity)="data">
          <b-badge
            style="background-color: #991d28"
            v-if="data.item.severity == 'Critical'"
            >Critical</b-badge
          >
          <b-badge variant="danger" v-else-if="data.item.severity == 'High'"
            >High</b-badge
          >
          <b-badge variant="warning" v-else-if="data.item.severity == 'Medium'"
            >Medium</b-badge
          >
          <b-badge variant="primary" v-else-if="data.item.severity == 'Low'"
            >Low</b-badge
          >
          <b-badge variant="success" v-else-if="data.item.severity == 'Info'"
            >Info</b-badge
          >
          <b-badge variant="secondary" v-else>Unknown</b-badge>
        </template>
        <template v-slot:cell(status_code)="data">
          <b-badge variant="success " v-if="data.item.status_code == 'PASS'"
            >PASS</b-badge
          >

          <b-badge variant="danger" v-else-if="data.item.status_code == 'FAIL'"
            >FAIL</b-badge
          >
          <b-badge variant="warning" v-else>{{
            data.item.status_code
          }}</b-badge>
        </template>
        <template #cell(provider)="data">
          <span class="text-uppercase">{{ data.item.provider }}</span>
        </template>
        <template #cell(service)="data">
          <span class="text-capitalize">{{ data.item.service }}</span>
        </template>
        <template #cell(compliance)="data">
          <div style="white-space: nowrap; overflow: hidden">
            <div
              class="mx-1"
              style="text-overflow: ellipsis"
              v-for="(value, index) in Object.entries(
                JSON.parse(data.item.compliance)
              ).slice(0, expandedCompliance[data.item.id] || 4)"
              :key="index"
            >
              <h6>{{ index + 1 }}. {{ value[0] }}</h6>
            </div>
            <div
              class="d-flex justify-content-end"
              v-if="Object.keys(JSON.parse(data.item.compliance)).length > 4"
            >
              <a
                v-if="
                  Object.keys(JSON.parse(data.item.compliance)).length >
                  (expandedCompliance[data.item.id] || 4)
                "
                @click="showMoreCompliance(data.item.id)"
                >Show More</a
              >
              <a v-else @click="showLessCompliance(data.item.id)">Show Less</a>
            </div>
          </div>
        </template>
      </b-table>
      <div v-else>
        <div
          class="w-100 d-flex flex-column align-items-center justify-content-center py-2"
        >
          <feather-icon icon="CloudIcon" size="34" class="mb-50" />
          <h3 class="font-weight-bolder">No Cloud Configuration</h3>
          <!-- <p>
            To add a new Cloud Configuration,
            <span
              class="font-weight-bold text-success cursor-pointer"
              @click="gotoCreateCloud()"
              >Click here</span
            >
          </p> -->
        </div>
      </div>
    </div>
    <b-pagination-nav
      v-model="currentPage"
      :numberOfPages="total"
      :total-rows="rows"
      :link-gen="linkGen"
      :per-page="perPage"
      :align="pagination_pos"
      aria-controls="auditTable"
      use-router
    />
    <b-sidebar
      id="sidebar-details"
      sidebar-class="sidebar-lg"
      bg-variant="white"
      shadow
      backdrop
      no-header
      right
      width="50%"
    >
      <AuditFindingsDetails :details="details" :closeSidebar="closeSidebar" />
    </b-sidebar>
    <b-modal
      ref="modal-confirm"
      :title="modalTitle"
      hide-footer
      no-close-on-backdrop
      @hidden="closeModal"
      size="lg"
    >
      <b-card-text>
        {{ modalMessage }}
      </b-card-text>
      <div class="d-flex justify-content-end mt-1">
        <b-button @click="confirmAction" variant="warning" size="sm">
          Confirm
        </b-button>
      </div>
    </b-modal>
  </b-card-code>
</template>

<script>
import vSelect from "vue-select";
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import { BTable } from "bootstrap-vue";
import moment from "moment";
import {
  BPagination,
  BPaginationNav,
  BDropdown,
  BDropdownItem,
  BDropdownDivider,
  BForm,
  BFormInput,
  BFormSelect,
  BSpinner,
  BButton,
  BAvatar,
  BSidebar,
  BBadge,
  BFormCheckbox,
} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import AuditFindingsDetails from "./afSidebarDetails.vue";

export default {
  components: {
    BCardCode,
    BTable,
    BPagination,
    BPaginationNav,
    BDropdown,
    BDropdownItem,
    BDropdownDivider,
    BForm,
    BFormInput,
    BFormSelect,
    BSpinner,
    BButton,
    vSelect,
    BAvatar,
    BSidebar,
    AuditFindingsDetails,
    BBadge,
    BFormCheckbox,
  },
  data() {
    return {
      pagination_pos: "center",
      items: [],
      currentPage: 1,
      perPage: 10,
      rows: 0,
      fields: [
        { key: "index", label: "#", visible: true },
        { key: "selected_vulns", label: "Select", visible: true },
        { key: "title", label: "Title", visible: true },
        { key: "status_code", label: "Status Code", visible: true },
        {
          key: "cloud_configuration",
          label: "Cloud Configuration",
          visible: true,
        },
        { key: "compliance", label: "compliance", visible: true },
        { key: "severity", label: "Severity", visible: true },
        { key: "is_verified", label: "Verified", visible: false },
        { key: "provider", label: "Provider", visible: true },
        { key: "is_exploited", label: "Exploited", visible: false },
        { key: "service", label: "Service", visible: true },
        { key: "region", label: "Region", visible: true },
        // { key: "actions", label: "Actions" },
      ],
      is_staff: this.$store.state.app.user.is_staff,
      organization: this.$store.state.app.org_id,
      isloading: false,
      loading: false,
      openDeleteModal: false,
      cloudId: null,
      cloud_title: "",
      title_filter: "",
      cloud_type: "",
      cloudTtype: "",
      severity_filter: "",
      severity_filter_options: [
        { value: "Info", text: "Info" },
        { value: "Low", text: "Low" },
        { value: "Medium", text: "Medium" },
        { value: "High", text: "High" },
        { value: "Critical", text: "Critical" },
      ],
      status_code_filter: "",
      status_code_options: [
        { value: "PASS", text: "PASS" },
        { value: "FAIL", text: "FAIL" },
        { value: "MANUAL", text: "MANUAL" },
      ],
      details: {},
      configuration_filter: null,
      configuration_filter_options: [],
      statusSort: null,
      visibleComplianceCount: 4,
      compliance_filter: "",
      expandedCompliance: {},
      audit_count: 0,
      selected_action: null,
      selectedVulns: [],
      vuln_with_selected_options: [
        { value: null, text: "--Vulnerability Actions--" },
        { value: "mark_as_closed", text: "Mark as Closed" },
        { value: "mark_as_open", text: "Mark as Open" },
        { value: "mark_as_false_positive", text: "Mark as False Positive" },
        { value: "mark_as_exploited", text: "Mark as exploited" },
        { value: "mark_as_unexploited", text: "Mark as unexploited" },
        { value: "accept", text: "Accept the Risk" },
        { value: "mark_as_verified", text: "Mark as verified" },
        { value: "mark_as_unverified", text: "Mark as unverified" },
        // { value: "request_for_retest", text: "Request for retest" },
        { value: "delete", text: "Delete" },
      ],
      isCheckAllFilterResults: false,
      isCheckAll: false,
      filter: [],
      is_search_on: false,

      modalMessage: "",
      modalTitle: "Confirmation",
      modalStatus: null,
    };
  },
  computed: {
    visibleFields() {
      return this.fields.filter((field) => field.visible);
    },
    filteredOptions() {
      if (this.$store.state.app.user.is_staff) {
        // If user is staff, show all options
        return this.vuln_with_selected_options;
      } else {
        // If user is not staff, filter out "Mark as Verified" option
        return this.vuln_with_selected_options.filter(
          (option) =>
            option.value !== "mark_as_verified" &&
            option.value !== "mark_as_unverified"
        );
      }
    },
    isCheckboxDisabled() {
      return this.status_filter.length > 0;
    },
  },
  props: {
    tabIndex: {
      type: Number,
      required: true,
    },
  },
  watch: {
    tabIndex: function (newVal, oldVal) {
      if (newVal === 0) {
        this.load();
      }
    },
    currentPage: function (newVal, oldVal) {
      this.load(false);
    },
  },
  created: function () {
    if (this.tabIndex === 0) {
      this.load();
    }
  },
  mounted() {
    this.onVerticalCollapse();
  },
  filters: {
    formatdate: function (value) {
      if (value) {
        return moment(String(value)).format("DD-MMM-YY hh:mm A");
      }
    },
  },
  methods: {
    linkGen: function (pageNum) {
      return this.$router.currentRoute.path + "?page=" + pageNum;
    },
    setSort(sortValue) {
      this.statusSort = sortValue;
      this.load();
    },
    onVerticalCollapse() {
      let coll = true;
      this.$store.commit("verticalMenu/UPDATE_VERTICAL_MENU_COLLAPSED", coll);
    },
    load: function (reset = true) {
      this.loading = true;
      this.filter = [];
      if (reset) this.currentPage = 1;
      let url =
        process.env.VUE_APP_BASEURL +
        "organization/cloud-vulns?query=true" +
        "&page=" +
        this.currentPage;
      if (this.title_filter != "") {
        url = url + "&title=" + this.title_filter;
        this.filter.push({ title: this.title_filter });
      }
      if (this.severity_filter != "") {
        url = url + "&severity=" + this.severity_filter;
        this.filter.push({ severity: this.severity_filter });
      }
      if (this.status_code_filter != "") {
        url = url + "&status_code=" + this.status_code_filter;
        this.filter.push({ status_code: this.status_code_filter });
      }
      if (this.configuration_filter != null) {
        url = url + "&cloud_id=" + this.configuration_filter;
        this.filter.push({ cloud_id: this.configuration_filter });
      }
      if (this.statusSort != null) {
        url = url + "&status_code_sort_by=" + this.statusSort;
      }
      if (this.compliance_filter != "") {
        url = url + "&compliance=" + this.compliance_filter;
        this.filter.push({ compliance: this.compliance_filter });
      }
      if (this.filter.length) this.is_search_on = true;
      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url: url,
      };
      this.$http(options).then((res) => {
        this.items = res.data.results;
        this.rows = res.data.results.length;
        this.total = Math.ceil(res.data.count / this.perPage);
        this.audit_count = res.data.count;
        this.loading = false;
      });
    },
    breakTextIntoLines(text) {
      const maxCharacters = 35;
      const words = text.split(/\s+/);
      const lines = [];

      let currentLine = "";

      for (const word of words) {
        if ((currentLine + word).length <= maxCharacters) {
          currentLine += (currentLine ? " " : "") + word;
        } else {
          lines.push(currentLine);
          currentLine = word;
        }
      }
      if (currentLine) {
        lines.push(currentLine);
      }
      return lines.join("<br>");
    },
    showMoreCompliance(itemId) {
      const compliance = JSON.parse(
        this.items.find((item) => item.id === itemId).compliance
      );
      const totalItems = Object.keys(compliance).length;
      const currentCount = this.expandedCompliance[itemId] || 4; // default visible items
      const increment = 4;
      this.$set(
        this.expandedCompliance,
        itemId,
        Math.min(currentCount + totalItems, totalItems)
      );
    },
    showLessCompliance(itemId) {
      this.$set(this.expandedCompliance, itemId, 4); // Reset to default
    },

    fetchConfiguration: function (search) {
      if (search.length > 2) {
        let url =
          process.env.VUE_APP_BASEURL +
          "organization/cloud-config/all-cloud-confgs";
        const options = {
          method: "GET",
          headers: { "content-type": "application/json" },
          url: url,
        };
        var self = this;
        this.$http(options).then((res) => {
          this.configuration_filter_options = [];
          res.data.map(function (value, key) {
            let a = {
              value: res.data[key].id,
              text: res.data[key].nice_name,
            };
            self.configuration_filter_options.push(a);
          });
        });
      }
    },
    closeSidebar: function () {
      this.$root.$emit("bv::toggle::collapse", "sidebar-details");
      this.load();
    },
    goMore(details) {
      this.details = details;
      this.$root.$emit("bv::toggle::collapse", "sidebar-details");
    },
    refresh() {
      this.title_filter = "";
      this.severity_filter = "";
      this.status_code_filter = "";
      this.configuration_filter = null;
      this.compliance_filter = "";
      this.load();
    },
    checkAll: function () {
      if (this.isCheckAll) {
        this.items.forEach((item) => {
          const index = this.selectedVulns.indexOf(item.id);
          if (index < 0) this.selectedVulns.push(item.id);
        });
      } else {
        this.items.forEach((item) => {
          const index = this.selectedVulns.indexOf(item.id);
          this.selectedVulns.splice(index, 1);
          // }
        });
      }
    },
    updateCheckAll: function () {
      var if_all = 0;
      this.items.forEach((item) => {
        console.log("item", item.id);
        const index = this.selectedVulns.indexOf(item.id);
        if (index >= 0) {
          console.log(index);
          if_all++;
        }
      });
      if (if_all == this.items.length) {
        this.isCheckAll = true;
      } else {
        this.isCheckAll = false;
      }
    },
    doBulkAction: function () {
      if (!this.selectedVulns.length && !this.isCheckAllFilterResults) {
        alert("Please select some items");
        this.selected_action = null;
      }
      switch (this.selected_action) {
        case "mark_as_closed":
          this.showModal("Do you really want to close the selected issues?", 1);
          break;
        case "mark_as_false_positive":
          this.showModal(
            "Do you really want to mark as false positive the selected issues?",
            2
          );
          break;
        case "mark_as_open":
          this.showModal(
            "Do you really want to (re)open the selected issues?",
            0
          );
          break;
        case "accept":
          this.showModal(
            "Do you really want to accept the risk of selected issues?",
            3
          );
          break;
        case "delete":
          this.showModal(
            "Do you really want to delete the selected issues?",
            9
          );
          break;
        case "mark_as_verified":
          this.showModal("Do you really want to mark all as verified?", 10);
          break;
        case "mark_as_unverified":
          this.showModal("Do you really want to mark all as un-verified?", 11);
          break;

        case "mark_as_exploited":
          this.showModal("Do you really want to mark all as exploited?", 7);
          break;
        case "mark_as_unexploited":
          this.showModal("Do you really want to mark all as un-exploited?", 8);
          break;
        default:
          break;
      }
    },
    showModal(message, status) {
      this.modalMessage = message;
      this.modalStatus = status;
      this.$refs["modal-confirm"].show();
    },
    confirmAction() {
      this.changeStatus(this.modalStatus);

      this.$refs["modal-confirm"].hide();
    },
    changeStatus: function (status) {
      var self = this;
      this.loading = true;
      let data = {
        status: status,
        vulnerabilties: this.selectedVulns,
      };
      if (this.isCheckAllFilterResults && this.is_search_on) {
        data.filters = this.filter;
      }
      this.$http({
        method: "POST",
        data: data,
        headers: { "content-type": "application/json" },
        url:
          process.env.VUE_APP_BASEURL +
          "organization/cloud-vulns/vuln-bulk-action/",
      }).then((res) => {
        if (status == 9) {
          self.$toast({
            component: ToastificationContent,
            props: {
              title: "Deleted Successfully",
              icon: "EditIcon",
              variant: "success",
            },
          });
          this.selectedVulns = [];
          self.load(false);
        } else if (res.data.status == "success") {
          self.$toast({
            component: ToastificationContent,
            props: {
              title: res.data.message,
              icon: "EditIcon",
              variant: "success",
            },
          });
          this.selectedVulns = [];
          self.load(false);
        } else {
          self.$toast({
            component: ToastificationContent,
            props: {
              title: res.data.message,
              icon: "EditIcon",
              variant: "danger",
            },
          });
        }
        this.loading = false;
      });
      this.selected_action = null;
      this.isCheckAllFilterResults = false;
      this.isCheckAll = false;
      // this.closeModal();
    },
    closeModal() {
      this.modalMessage = "";
      this.modalStatus = null;
    },
  },
};
</script>
<style scoped>
::v-deep .dropdown-menu {
  max-height: 200px;
  overflow-y: auto;
}
</style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
