<template>
  <b-card>
    <div class="d-flex justify-content-end">
      <feather-icon
        class="ml-1 cursor-pointer"
        icon="XIcon"
        size="16"
        @click="closeSidebar()"
      />
    </div>
    <validation-observer ref="CloudConfigEditForm">
      <b-form class="auth-login-form mt-2" @submit.prevent="editCloudConfig">
        <!-- Cloud type-->
        <b-form-group label="cloud type" label-for="cloud type">
          <template #label>
            <span>Cloud Type</span>
            <span
              class="text-danger font-weight-bold h5"
              style="margin-left: 3px"
              >*</span
            >
          </template>
          <validation-provider
            #default="{ errors }"
            name="cloud type"
            rules="required"
          >
            <v-select
              v-model="cloudTtype"
              label="text"
              placeholder="--Select cloud type--"
              :options="cloud_type_options"
              autocomplete
              :reduce="(cloud) => cloud.value"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <!-- cloud Name -->
        <b-form-group label="cloud name" label-for="cloud name">
          <template #label>
            <span>Cloud Name</span>
            <span
              class="text-danger font-weight-bold h5"
              style="margin-left: 3px"
              >*</span
            >
          </template>
          <validation-provider
            #default="{ errors }"
            name="Cloud Name"
            :rules="{
              required: true,
              regex: /^[a-zA-Z0-9\s\(\)\ [\]\ {\} \|\/ ]+$/,
            }"
          >
            <b-form-input
              id="cloud_name"
              v-model="cloudtitle"
              :state="errors.length > 0 ? false : null"
              name="cloud_name"
              placeholder="Please enter cloud name"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <b-button
          type="submit"
          variant="primary"
          :disabled="cloudtitle === '' || disable"
          class="my-1"
          size="sm"
        >
          <span v-if="isloading"
            ><b-spinner type="border" small></b-spinner> Please wait</span
          >
          <span v-else>Update</span>
        </b-button>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import vSelect from "vue-select";
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import {
  BCard,
  BTabs,
  BTab,
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BForm,
  BButton,
  BFormTextarea,
  BInputGroupAppend,
  BInputGroup,
  BFormSelect,
  BFormCheckbox,
  BFormFile,
  BSpinner,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";

export default {
  components: {
    BCard,
    BCardCode,
    BForm,
    BFormInput,
    BFormTextarea,
    BFormGroup,
    BRow,
    BButton,
    BCol,
    ValidationProvider,
    ValidationObserver,
    BInputGroupAppend,
    BInputGroup,
    BFormSelect,
    BFormCheckbox,
    BTabs,
    BTab,
    BFormFile,
    BSpinner,
    vSelect,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      cloud_nice_name: "",
      cloud_type: "",
      cloud_type_options: [
        { value: "aws", text: "AWS" },
        { value: "azure", text: "Azure" },
        { value: "gcp", text: "Google Cloud" },
        { value: "kubernetes", text: "Kubernetes" },
      ],
      isloading: false,
      disable: false,
      access_key: "",
      secret_key: "",
    };
  },
  props: {
    cloudTtype: {
      type: String,
      required: true,
    },
    cloudtitle: {
      type: String,
      required: true,
    },
    closeSidebar: {
      type: Function,
      required: true,
    },
    cloudId: {
      type: Number,
      required: true,
    },
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
  },
  created: function () {
    // this.load();
  },

  methods: {
    editCloudConfig: function () {
      this.$refs.CloudConfigEditForm.validate()
        .then((success) => {
          this.disable = true;
          this.isloading = true;
          if (success) {
            //call to axios
            var self = this;
            let data = {
              cloud_type: this.cloudTtype,
              nice_name: this.cloudtitle,
            };
            const options = {
              method: "PATCH",
              headers: { "content-type": "application/json" },
              data: data,
              url:
                process.env.VUE_APP_BASEURL +
                "organization/cloud-config/" +
                this.cloudId + "/",
            };
            this.$http(options).then((res) => {
              if (res.data.id) {
                self.$toast({
                  component: ToastificationContent,
                  props: {
                    title: "Cloud Configuration Updated successfully",
                    icon: "EditIcon",
                    variant: "success",
                  },
                });
                this.closeSidebar();
              }
              this.isloading = false;
              this.disable = false;
            });
          }
        })
        .catch((err) => {
          this.isloading = false;
          this.disable = false;
        })
        .finally(() => {
          this.isloading = false;
          this.disable = false;
        });
    },
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>